import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'

import { rhythm, scale } from '../utils/typography'

import '../styles/app.css'

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.16),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            Speedrank - Dein Web Performance &amp; SEO Blog
          </Link>
        </h1>
      )
    } else {
      header = (
        <span
          style={{
            display: 'block',
            fontSize: '1.5rem',
            fontWeight: '700',
            lineHeight: '1.1',
            marginTop: 0,
            marginBottom: rhythm(-1),
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </span>
      )
    }
    return (
      <div>
        <nav>
          <div style={{
            alignItems: 'center',
            display: 'flex',
            margin: '0 auto',
            maxWidth: '1344px',
            minHeight: '4rem',
            width: '100%'
            }}
          >
            <div>
              <Link
                style={{
                  alignItems: 'center',
                  color: '#222',
                  cursor: 'pointer',
                  display: 'flex',
                  flexGrow: '0',
                  flexShrink: '0',
                  fontSize: '1.35rem',
                  fontWeight: '700',
                  lineHeight: '1.5',
                  padding: '.5rem .75rem',
                  boxShadow: 'none',
                  textTransform: 'uppercase'
                }}
                to='/'
              >
              <div
                style={{
                  background: '#ffd83c',
                  borderRadius: '50%',
                  fontSize: '1.3rem',
                  marginRight: '1rem',
                  padding: '8px 9px 2px 12px'
                }}
              >
                <svg
                  stroke='currentColor'
                  fill='currentColor'
                  strokeWidth='0'
                  viewBox='0 0 320 512'
                  height='1em'
                  width='1em'
                  aria-labelledby='logoTitle'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path d='M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 8 29.5 23.3 29.5 8.4 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36z'></path>
                  <title id='logoTitle'>The Speedrank Logo - A black bolt within a yellow circle</title>
                </svg>
              </div>
              Speedrank
              <span style={{ fontWeight: '400'}}>&nbsp;|&nbsp;Blog</span>
              </Link>
            </div>
          </div>
        </nav>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          {header}
          {children}
          <footer
            style={{
              marginTop: rhythm(2.5),
              textAlign: 'center'
            }}
          >
            <p>© {new Date().getFullYear()} Speedrank | Produktgenuss GmbH</p>
            <div
              class='footer--nav'
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <a href='https://speedrank.app/de/agb'>AGB &amp; Widerrufsbelehrung</a>
              <a href='https://speedrank.app/de/datenschutz'>Datenschutz</a>
              <a href='https://speedrank.app/de/impressum'>Impressum</a>
              <a href='https://speedrank.app/'>Speedrank Performance Tool</a>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                margin: '2rem auto 0',
                width: '50%',
              }}
            >
              <a
                class='social-icon'
                href='https://twitter.com/speedrankapp'
                rel='noopener noreferrer'
                target='_blank'
                style={{ background: '#3BA9EE', margin: '0 0 1rem' }}
              >
                <svg
                  width='40'
                  viewBox='0 0 20 18'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{
                    display: 'block',
                    margin: 'auto'
                  }}
                >
                  <title>Twitter Logo</title>
                  <path
                    d='M18.258,3.266c-0.693,0.405-1.46,0.698-2.277,0.857c-0.653-0.686-1.586-1.115-2.618-1.115c-1.98,0-3.586,1.581-3.586,3.53c0,0.276,0.031,0.545,0.092,0.805C6.888,7.195,4.245,5.79,2.476,3.654C2.167,4.176,1.99,4.781,1.99,5.429c0,1.224,0.633,2.305,1.596,2.938C2.999,8.349,2.445,8.19,1.961,7.925C1.96,7.94,1.96,7.954,1.96,7.97c0,1.71,1.237,3.138,2.877,3.462c-0.301,0.08-0.617,0.123-0.945,0.123c-0.23,0-0.456-0.021-0.674-0.062c0.456,1.402,1.781,2.422,3.35,2.451c-1.228,0.947-2.773,1.512-4.454,1.512c-0.291,0-0.575-0.016-0.855-0.049c1.588,1,3.473,1.586,5.498,1.586c6.598,0,10.205-5.379,10.205-10.045c0-0.153-0.003-0.305-0.01-0.456c0.7-0.499,1.308-1.12,1.789-1.827c-0.644,0.28-1.334,0.469-2.06,0.555C17.422,4.782,17.99,4.091,18.258,3.266'
                    fill='#fff'
                  />
                </svg>
              </a>
              <a
                class='social-icon'
                href='https://facebook.com/speedrankapp'
                rel='noopener noreferrer'
                target='_blank'
                style={{ background: '#4267b2', margin: '0 0 1rem' }}
              >
                <svg
                  width='40'
                  viewBox='0 0 20 18'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{
                    display: 'block',
                    margin: 'auto'
                  }}
                >
                  <title>Facebook Logo</title>
                  <path
                    d='M11.344,5.71c0-0.73,0.074-1.122,1.199-1.122h1.502V1.871h-2.404c-2.886,0-3.903,1.36-3.903,3.646v1.765h-1.8V10h1.8v8.128h3.601V10h2.403l0.32-2.718h-2.724L11.344,5.71z'
                    fill='#fff'
                  />
                </svg>
              </a>
              <a
                class='social-icon'
                href='https://www.youtube.com/channel/UC3T6ZK4I48AKSq5BqoPxg4w'
                rel='noopener noreferrer'
                target='_blank'
                style={{ background: '#FF0000', margin: '0 0 1rem' }}
              >
                <svg
                  width='40'
                  viewBox='0 0 20 18'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{
                    display: 'block',
                    margin: 'auto'
                  }}
                >
                  <title>YouTube Logo</title>
                  <path
                    d='M9.426,7.625h0.271c0.596,0,1.079-0.48,1.079-1.073V4.808c0-0.593-0.483-1.073-1.079-1.073H9.426c-0.597,0-1.079,0.48-1.079,1.073v1.745C8.347,7.145,8.83,7.625,9.426,7.625 M9.156,4.741c0-0.222,0.182-0.402,0.404-0.402c0.225,0,0.405,0.18,0.405,0.402V6.62c0,0.222-0.181,0.402-0.405,0.402c-0.223,0-0.404-0.181-0.404-0.402V4.741z M12.126,7.625c0.539,0,1.013-0.47,1.013-0.47v0.403h0.81V3.735h-0.81v2.952c0,0-0.271,0.335-0.54,0.335c-0.271,0-0.271-0.202-0.271-0.202V3.735h-0.81v3.354C11.519,7.089,11.586,7.625,12.126,7.625 M6.254,7.559H7.2v-2.08l1.079-2.952H7.401L6.727,4.473L6.052,2.527H5.107l1.146,2.952V7.559z M11.586,12.003c-0.175,0-0.312,0.104-0.405,0.204v2.706c0.086,0.091,0.213,0.18,0.405,0.18c0.405,0,0.405-0.451,0.405-0.451v-2.188C11.991,12.453,11.924,12.003,11.586,12.003 M14.961,8.463c0,0-2.477-0.129-4.961-0.129c-2.475,0-4.96,0.129-4.96,0.129c-1.119,0-2.025,0.864-2.025,1.93c0,0-0.203,1.252-0.203,2.511c0,1.252,0.203,2.51,0.203,2.51c0,1.066,0.906,1.931,2.025,1.931c0,0,2.438,0.129,4.96,0.129c2.437,0,4.961-0.129,4.961-0.129c1.117,0,2.024-0.864,2.024-1.931c0,0,0.202-1.268,0.202-2.51c0-1.268-0.202-2.511-0.202-2.511C16.985,9.328,16.078,8.463,14.961,8.463 M7.065,10.651H6.052v5.085H5.107v-5.085H4.095V9.814h2.97V10.651z M9.628,15.736h-0.81v-0.386c0,0-0.472,0.45-1.012,0.45c-0.54,0-0.606-0.515-0.606-0.515v-3.991h0.809v3.733c0,0,0,0.193,0.271,0.193c0.27,0,0.54-0.322,0.54-0.322v-3.604h0.81V15.736z M12.801,14.771c0,0,0,1.03-0.742,1.03c-0.455,0-0.73-0.241-0.878-0.429v0.364h-0.876V9.814h0.876v1.92c0.135-0.142,0.464-0.439,0.878-0.439c0.54,0,0.742,0.45,0.742,1.03V14.771z M15.973,12.39v1.287h-1.688v0.965c0,0,0,0.451,0.405,0.451s0.405-0.451,0.405-0.451v-0.45h0.877v0.708c0,0-0.136,0.901-1.215,0.901c-1.08,0-1.282-0.901-1.282-0.901v-2.51c0,0,0-1.095,1.282-1.095S15.973,12.39,15.973,12.39 M14.69,12.003c-0.405,0-0.405,0.45-0.405,0.45v0.579h0.811v-0.579C15.096,12.453,15.096,12.003,14.69,12.003'
                    fill='#fff'
                  />
                </svg>
              </a>
              <a
                class='social-icon'
                href='https://medium.com/speedrank-app'
                rel='noopener noreferrer'
                target='_blank'
                style={{ background: '#000000d6', margin: '0 0 1rem' }}
              >
                <svg
                  width='35'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{
                    display: 'block',
                    margin: 'auto'
                  }}
                >
                  <title>Medium icon</title>
                  <path
                    d="M0 0v24h24V0H0zm19.938 5.686L18.651 6.92a.376.376 0 0 0-.143.362v9.067a.376.376 0 0 0 .143.361l1.257 1.234v.271h-6.322v-.27l1.302-1.265c.128-.128.128-.165.128-.36V8.99l-3.62 9.195h-.49L6.69 8.99v6.163a.85.85 0 0 0 .233.707l1.694 2.054v.271H3.815v-.27L5.51 15.86a.82.82 0 0 0 .218-.707V8.027a.624.624 0 0 0-.203-.527L4.019 5.686v-.27h4.674l3.613 7.923 3.176-7.924h4.456v.271z"
                    fill='#fff'
                  />
                </svg>
              </a>
            </div>
          </footer>
        </div>
        <CookieConsent
          buttonText='Nicht mehr anzeigen'
          buttonStyle={{ background: '#ffd83c' }}
          cookieName='_speedrank_blog_cookie_consent'
          style={{ background: 'rgba(54,54,54,.85)' }}
        >
          Wir verwenden Cookies. Durch die weitere Nutzung der Webseite stimmst Du der Verwendung von Cookies zu.
          Weitere Informationen erhältst Du auf dieser <a style={{ color: '#fff' }} href='https://speedrank.app/de/datenschutz'>Seite</a>.
        </CookieConsent>
      </div>
    )
  }
}

export default Layout
