import Typography from 'typography'
import Wordpress2016 from 'typography-theme-wordpress-2016'

Wordpress2016.overrideThemeStyles = () => {
  return {
    '.fancy-checkbox': {
      fontWeight: '400',
    },
    '.fancy-checkbox input[type="checkbox"]': {
      marginRight: '10px'
    },
    '.form-group': {
      marginBottom: '1rem'
    },
    '.form-control': {
      mozBoxShadow: 'none',
      webkitBoxShadow: 'none',
      boxShadow: 'none',
      backgroundColor: '#fff',
      fontSize: '14px',
    },
    '.form-control': {
      display: 'block',
      width: '100%',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#495057',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
    },
    '.gatsby-resp-image-image': {
      left: '0',
      position: 'absolute',
      top: '0'
    },
    '.social-icon': {
      borderRadius: '15px',
      boxShadow: 'none',
      margin: 'auto 1rem',
      padding: '15px',
      transition: 'all .2s',
      width: '4.25rem'
    },
    '.social-icon:hover': {
      background: '#007acc !important',
      transform: 'translateY(-2px)'
    },
    '.social-icon:active': {
      transform: 'translateY(0)'
    },
    'a.gatsby-resp-image-link': {
      boxShadow: 'none'
    },
    'body, h1': {
      fontFamily: 'Lato, Georgia, sans-serif'
    },
    'code': {
      background: '#1b1f230d',
      borderRadius: '5px',
      color: '#555',
      fontSize: '1.15rem',
      padding: '.2rem .4rem'
    },
    'footer p': {
      fontSize: '1rem'
    },
    'h2, h3, h4, h5, h6': {
      fontWeight: '700'
    },
    'label': {
      display: 'inline-block',
      fontWeight: '600',
      marginBottom: '0.5rem'
    },
    'p': {
      fontSize: '1.25rem',
      lineHeight: '1.75rem'
    },
    'pre': {
      textAlign: 'justify',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    },
    'ol, ul': {
      fontSize: '1.25rem',
      listStylePosition: 'inside'
    }
  }
}
Wordpress2016.headerFontFamily = ["Lato", "sans-serif"]

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
